import React from "react"
import { PageProps, graphql, Link } from "gatsby"
import { MainLayout } from "@components/layouts"
import { Query, SitePageContext } from "types/gatsby-graphql"
import { Card, Seo } from "@components"
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa"
import { getExcerptText } from "@utils/blogUtils"

interface IBlogListProps extends PageProps {
  data: Query
  pageContext: SitePageContext
}

const BlogList: React.VFC<IBlogListProps> = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges
  const { currentPage: currPage, numPages } = pageContext
  const currentPage = currPage || 0
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <MainLayout>
      <Seo title="บทความทั้งหมด" />
      <h1 className="title has-text-centered">บทความทั้งหมด</h1>
      <div className="columns is-multiline blog-cards">
        {posts.map(({ node }, key) => (
          <div className="column is-4" key={key}>
            <Card
              fields={node.fields!!}
              frontmatter={node.frontmatter!!}
              excerpt={getExcerptText(node.html!!)}
            />
          </div>
        ))}
      </div>

      <div className="columns">
        {!isFirst && (
          <div className="column">
            <Link
              to={`/blog/${prevPage}`}
              rel="prev"
              className="is-flex is-align-items-center"
            >
              <FaLongArrowAltLeft /> <span className="ml-2">Previous Page</span>
            </Link>
          </div>
        )}
        {!isLast && (
          <div className="column">
            <Link
              to={`/blog/${nextPage}`}
              rel="next"
              className="is-flex is-justify-content-flex-end is-align-items-center"
            >
              <span className="mr-2">Next Page</span>
              <FaLongArrowAltRight />
            </Link>
          </div>
        )}
      </div>
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default BlogList
